<template>
    <div>文档</div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

</style>>
